<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="!loading && permission.read">
      <b-col>
        <b-card >
          <b-container>
            <b-row>
              <b-col sm="12" md="8">
                <p><b-link :to="{ name: 'Sanctions' }">all Sanctions</b-link></p>
                <h2>
                  <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'sanction' }}">Edit</b-button>
                  {{item.name}}
                </h2>
                <delete-standard
                  v-if="permission.delete && step.delete"
                  :id="item.id"
                  model="sanction"
                ></delete-standard>
                <div v-if="item.urn"><strong>Reference id: {{item.urn}}</strong></div>
                <div v-if="item.link"><a :href="item.link" target="_blank">external link</a></div>
                <h3 v-if="item.body">Description</h3>
                <div v-if="item.body" v-html="item.body"></div>
              </b-col>
              <b-col>
                <h4>Workflow</h4>
                <div>status: <strong>{{step.name}}</strong></div>
                <div><small>{{item.workflow_created_by}} @ {{new Intl.DateTimeFormat().format(new Date(item.workflow_created_at))}}</small></div>
                <div>
                  <span v-if="!savingStep && permission.edit">
                    <b-button class="mr-4" v-if="step.downStep && permissionWorkflowDown" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
                    <b-button v-if="step.upStep && permissionWorkflowUp" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
                  </span>
                  <span v-if="savingStep">
                    <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                  </span>
                </div>
                <h4>Period</h4>
                <div><span v-if="item.start">start: {{new Intl.DateTimeFormat().format(new Date(item.start))}}</span><span v-else>no start date</span></div>
                <div><span v-if="item.end">end: {{new Intl.DateTimeFormat().format(new Date(item.end))}}</span><span v-else>no end date</span></div>
                <h4>US involved</h4>
                <div>{{item.us}}</div>
                <h4>Third parties involved</h4>
                <div>{{item.third}}</div>
                <div v-if="item.countries.length > 0">
                  <h4>Jurisdictions</h4>
                  <div v-for="row in item.countries" :key="'jurisdiction' + row.id">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.regulators.length > 0">
                  <h4>Regulators</h4>
                  <div v-for="row in item.regulators" :key="'regulators' + row.id">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.schannels.length > 0">
                  <h4>Channels</h4>
                  <div v-for="row in item.schannels" :key="'schannels' + row.id">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.scustomers.length > 0">
                  <h4>Customers</h4>
                  <div v-for="row in item.scustomers" :key="'scustomers' + row.id">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.sparties.length > 0">
                  <h4>Parties</h4>
                  <div v-for="row in item.sparties" :key="'sparties' + row.id">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.sproducts.length > 0">
                  <h4>Products</h4>
                  <div v-for="row in item.sproducts" :key="'sproducts' + row.id">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.ssectors.length > 0">
                  <h4>Sectors</h4>
                  <div v-for="row in item.ssectors" :key="'ssectors' + row.id">
                    {{row.name}}
                  </div>
                </div>
                <div v-if="item.stypes.length > 0">
                  <h4>Types</h4>
                  <div v-for="row in item.stypes" :key="'stypes' + row.id">
                    {{row.name}}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import workflow from '@/workflows/sanction'
import DeleteStandard from '@/components/DeleteStandard'

export default {
  name: 'Case',
  components: {
    DeleteStandard
  },
  computed: {
    permissionWorkflowDown: function () {
      let result = false
      if (this.step.id > 7 && ac.can(this.user.acgroups).readAny('sanctionWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id <= 7 && ac.can(this.user.acgroups).createAny('sanction').granted) {
        result = true
      }
      return result
    },
    permissionWorkflowUp: function () {
      let result = false
      if (this.step.id >= 7 && ac.can(this.user.acgroups).readAny('sanctionWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id < 7 && ac.can(this.user.acgroups).createAny('sanction').granted) {
        result = true
      }
      return result
    },
    step: function () {
      const step = workflow.find(x => x.id === this.item.workflow_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'sanctioon', action: 'open sanction', model: 'sanction', model_id: this.$route.params.id })
    this.permission.read = ac.can(this.user.acgroups).readAny('sanction').granted
    this.permission.edit = ac.can(this.user.acgroups).updateAny('sanction').granted
    this.permission.delete = ac.can(this.user.acgroups).deleteAny('sanction').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      item: null,
      moment: moment,
      permission: {
        delete: false,
        edit: false,
        read: false
      },
      savingStep: false
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const apiName = 'cosmos'
        const path = `/sanction/${this.$route.params.id}`
        this.item = await this.$Amplify.API.get(apiName, path)
        document.title = this.item.name
        console.log(this.item)
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        const params = {
          body: {
            workflow_id: Number.parseInt(id)
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/sanction/${this.item.id}`, params)
        this.item.workflow_id = id
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  }
}
</script>

<style scoped>
h3 {
  margin-top: 1.5em;
}
h4 {
  margin-top: 1.5em;
}
</style>
